angular.module('fingerink')
    .service('fingerinkDocumentImage', function (signatureService, templateService, $timeout) {
        this.getImages = (document, token) => {
            var images = new Array(document.pages);
            var page = 0;

            function chargePage() {
                var promise;
                if (document.template && document.template.id) {
                    promise = templateService.getURL(document.template.id, page);
                } else {
                    if (token) {
                        promise = signatureService.getTokenURL(token, document.id, page);
                    } else {
                        promise = signatureService.getURL(document.id, page);
                    }
                }
                promise.then((response) => {
                    if (response.data) {
                        images[page] = response.data;
                        page++;
                        if (page < document.pages) {
                            chargePage();
                        }
                    } else {
                        $timeout(chargePage, 1000);
                    }
                });
            }

            chargePage();
            return images;
        };

    });
